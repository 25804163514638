export const categories = [
  {
    id: 1,
    name: "SOFTBALL",
    slug: "softball",
    alias: "jpsb",
  },
  {
    id: 2,
    name: "MOTORCROSS",
    slug: "mx",
    alias: "jpmx",
  },
//   {
//     id: 3,
//     name: "ARCHERY",
//     slug: "archery",
//     alias: "jparc",
//   },
];

export const products = {
    softball : [
        {
            id: 1,
            name: "BHAYANGKARA",
            slug: "jpsb-1",
            image: {
                front : require('../assets/images/SOFTBALL/BHAYANGKARA/FRONT-1.png'),
                back : require('../assets/images/SOFTBALL/BHAYANGKARA/BACK.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/BHAYANGKARA/FRONT-1.png'),
                require('../assets/images/SOFTBALL/BHAYANGKARA/BACK.png'),
            ]
        },
        {
            id: 2,
            name: "BLIND SKULL",
            slug: "jpsb-2",
            image: {
                front : require('../assets/images/SOFTBALL/BLIND SKULL/blind-skul-depan.png'),
                back : require('../assets/images/SOFTBALL/BLIND SKULL/blind-skul-belakang.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/BLIND SKULL/blind-skul-depan.png'),
                require('../assets/images/SOFTBALL/BLIND SKULL/blind-skul-belakang.png'),
            ]
        },
        {
            id: 3,
            name: "BLOOD ARMY",
            slug: "jpsb-3",
            image: {
                front : require('../assets/images/SOFTBALL/BLOOD ARMY/front.png'),
                back : require('../assets/images/SOFTBALL/BLOOD ARMY/back.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/BLOOD ARMY/front.png'),
                require('../assets/images/SOFTBALL/BLOOD ARMY/back.png'),
            ]
        },
        {
            id: 4,
            name: "DESTRUCTION SOFTBALL",
            slug: "jpsb-4",
            image: {
                front : require('../assets/images/SOFTBALL/DESTRUCTION SOFTBALL/catalog-template 2.png'),
                back : require('../assets/images/SOFTBALL/DESTRUCTION SOFTBALL/catalog-template1.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/DESTRUCTION SOFTBALL/catalog-template 2.png'),
                require('../assets/images/SOFTBALL/DESTRUCTION SOFTBALL/catalog-template1.png'),
            ]
        },
        {
            id: 5,
            name: "HARBOUR WAVE",
            slug: "jpsb-5",
            image: {
                front : require('../assets/images/SOFTBALL/HARBOUR WAVE/catalog-template.png'),
                back : require('../assets/images/SOFTBALL/HARBOUR WAVE/catalog-template-2.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/HARBOUR WAVE/catalog-template.png'),
                require('../assets/images/SOFTBALL/HARBOUR WAVE/catalog-template-2.png'),
            ]
        },
        {
            id: 6,
            name: "MATRIX",
            slug: "jpsb-6",
            image: {
                front : require('../assets/images/SOFTBALL/MATRIX/front.png'),
                back : require('../assets/images/SOFTBALL/MATRIX/back.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/MATRIX/front.png'),
                require('../assets/images/SOFTBALL/MATRIX/back.png'),
            ]
        },
        {
            id: 7,
            name: "MMXXIV",
            slug: "jpsb-7",
            image: {
                front : require('../assets/images/SOFTBALL/MMXXIV/catalog-template[1].png'),
                back : require('../assets/images/SOFTBALL/MMXXIV/catalog-template2.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/MMXXIV/catalog-template[1].png'),
                require('../assets/images/SOFTBALL/MMXXIV/catalog-template2.png'),
            ]
        },
        {
            id: 8,
            name: "MONSTER SLIME",
            slug: "jpsb-8",
            image: {
                front : require('../assets/images/SOFTBALL/MONSTER SLIME/front.png'),
                back : require('../assets/images/SOFTBALL/MONSTER SLIME/back.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/MONSTER SLIME/front.png'),
                require('../assets/images/SOFTBALL/MONSTER SLIME/back.png'),
            ]
        },
        {
            id: 9,
            name: "MONSTER SWINGERS",
            slug: "jpsb-9",
            image: {
                front : require('../assets/images/SOFTBALL/MONSTER SWINGERS/monster-depan.png'),
                back : require('../assets/images/SOFTBALL/MONSTER SWINGERS/monster-belakang.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/MONSTER SWINGERS/monster-depan.png'),
                require('../assets/images/SOFTBALL/MONSTER SWINGERS/monster-belakang.png'),
            ]
        },
        {
            id: 10,
            name: "ORANG ECLAW",
            slug: "jpsb-10",
            image: {
                front : require('../assets/images/SOFTBALL/ORANG ECLAW/front.png'),
                back : require('../assets/images/SOFTBALL/ORANG ECLAW/back.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/ORANG ECLAW/front.png'),
                require('../assets/images/SOFTBALL/ORANG ECLAW/back.png'),
            ]
        },
        {
            id: 11,
            name: "PAINTER",
            slug: "jpsb-11",
            image: {
                front : require('../assets/images/SOFTBALL/PAINTER/jersey-biasa-depan.png'),
                back : require('../assets/images/SOFTBALL/PAINTER/jersey-biasa-belakang.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/PAINTER/jersey-biasa-depan.png'),
                require('../assets/images/SOFTBALL/PAINTER/jersey-biasa-belakang.png'),
            ]
        },
        {
            id: 12,
            name: "PATRIOTS",
            slug: "jpsb-12",
            image: {
                front : require('../assets/images/SOFTBALL/PATRIOTS/front (1).png'),
                back : require('../assets/images/SOFTBALL/PATRIOTS/back (1).png'),
            },
            images: [
                require('../assets/images/SOFTBALL/PATRIOTS/front (1).png'),
                require('../assets/images/SOFTBALL/PATRIOTS/back (1).png'),
            ]
        },
        {
            id: 13,
            name: "STAR EAGELS",
            slug: "jpsb-13",
            image: {
                front : require('../assets/images/SOFTBALL/STAR EAGELS/front.png'),
                back : require('../assets/images/SOFTBALL/STAR EAGELS/back.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/STAR EAGELS/front.png'),
                require('../assets/images/SOFTBALL/STAR EAGELS/back.png'),
            ]
        },
        {
            id: 14,
            name: "TORNADO",
            slug: "jpsb-14",
            image: {
                front : require('../assets/images/SOFTBALL/TORNADO/jersey-biasa-depan.png'),
                back : require('../assets/images/SOFTBALL/TORNADO/jersey-biasa-belakang.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/TORNADO/jersey-biasa-depan.png'),
                require('../assets/images/SOFTBALL/TORNADO/jersey-biasa-belakang.png'),
            ]
        },
        {
            id: 15,
            name: "TROPICAL",
            slug: "jpsb-15",
            image: {
                front : require('../assets/images/SOFTBALL/TROPICAL/front.png'),
                back : require('../assets/images/SOFTBALL/TROPICAL/back.png'),
            },
            images: [
                require('../assets/images/SOFTBALL/TROPICAL/front.png'),
                require('../assets/images/SOFTBALL/TROPICAL/back.png'),
            ]
        },
    ],
    mx : [
        {
            id: 1,
            name: "80COMIC",
            slug: "jpmx-1",
            image: {
                front : require('../assets/images/MX/80COMIC/H-JERSEY 80 COMIC.png'),
                back : require('../assets/images/MX/80COMIC/T-JERSEY 80 COMIC.png'),
            },
            images: [
                require('../assets/images/MX/80COMIC/H-JERSEY 80 COMIC.png'),
                require('../assets/images/MX/80COMIC/T-JERSEY 80 COMIC.png'),
                require('../assets/images/MX/80COMIC/3-H JIPPER ONEAL PANTS.png'),
                require('../assets/images/MX/80COMIC/3-T JIPPER ONEAL PANTS.png'),
                require('../assets/images/MX/80COMIC/DECAL-HUSQVARNA 80 COMIC.png'),
                require('../assets/images/MX/80COMIC/H-PANTS 80 COMIC.png'),
                require('../assets/images/MX/80COMIC/T-PANTS 80 COMIC.png'),
            ]
        },
        {
            id: 2,
            name: "AMOORT RACING",
            slug: "jpmx-2",
            image: {
                front : require('../assets/images/MX/AMOORT RACING/H-JERSEY AMOORT.png'),
                back : require('../assets/images/MX/AMOORT RACING/T-JERSEY AMOORT.png'),
            },
            images: [
                require('../assets/images/MX/AMOORT RACING/H-JERSEY AMOORT.png'),
                require('../assets/images/MX/AMOORT RACING/T-JERSEY AMOORT.png'),
                require('../assets/images/MX/AMOORT RACING/H-AMOORT PANTS.png'),
                require('../assets/images/MX/AMOORT RACING/T-AMOORT PANTS.png'),
            ]
        },
        {
            id: 3,
            name: "AQUATROZ",
            slug: "jpmx-3",
            image: {
                front : require('../assets/images/MX/AQUATROZ/aquatroz-front.png'),
                back : require('../assets/images/MX/AQUATROZ/aquatroz-back.png'),
            },
            images: [
                require('../assets/images/MX/AQUATROZ/aquatroz-front.png'),
                require('../assets/images/MX/AQUATROZ/aquatroz-back.png'),
                require('../assets/images/MX/AQUATROZ/aquatroz-pback.png'),
                require('../assets/images/MX/AQUATROZ/aquatroz-pfront.png'),
            ]
        },
        {
            id: 4,
            name: "ARIZONS",
            slug: "jpmx-4",
            image: {
                front : require('../assets/images/MX/ARIZONS/C2-JERSEY-ARIZONS-FRONT.png'),
                back : require('../assets/images/MX/ARIZONS/C2-JERSEY-ARIZONS-BACK.png'),
            },
            images: [
                require('../assets/images/MX/ARIZONS/C2-JERSEY-ARIZONS-FRONT.png'),
                require('../assets/images/MX/ARIZONS/C2-JERSEY-ARIZONS-BACK.png'),
                require('../assets/images/MX/ARIZONS/C2-PANT-ARIZONS-FRONT.png'),
                require('../assets/images/MX/ARIZONS/C2-PANT-ARIZONS-BACK.png'),
            ]
        },
        {
            id: 5,
            name: "BLUE-JIPPER",
            slug: "jpmx-5",
            image: {
                front : require('../assets/images/MX/BLUE-JIPPER/jersey-blue-font.png'),
                back : require('../assets/images/MX/BLUE-JIPPER/jersey-blue-back.png'),
            },
            images: [
                require('../assets/images/MX/BLUE-JIPPER/jersey-blue-font.png'),
                require('../assets/images/MX/BLUE-JIPPER/jersey-blue-back.png'),
                require('../assets/images/MX/BLUE-JIPPER/pant-back.png'),
                require('../assets/images/MX/BLUE-JIPPER/pant-font.png'),
            ]
        },
        {
            id: 6,
            name: "BRHN",
            slug: "jpmx-6",
            image: {
                front : require('../assets/images/MX/BRHN/H-BURHAN JERSEY.png'),
                back : require('../assets/images/MX/BRHN/T-BURHAN JERSEY.png'),
            },
            images: [
                require('../assets/images/MX/BRHN/H-BURHAN JERSEY.png'),
                require('../assets/images/MX/BRHN/T-BURHAN JERSEY.png'),
                require('../assets/images/MX/BRHN/H-BURHAN PANTS.png'),
                require('../assets/images/MX/BRHN/T-BURHAN PANTS.png'),
                require('../assets/images/MX/BRHN/REV2B-BURHAN RACING JERSEY.png'),
                require('../assets/images/MX/BRHN/REV2F-BURHAN RACING JERSEY.png'),
            ]
        },
        {
            id: 7,
            name: "EDGY",
            slug: "jpmx-7",
            image: {
                front : require('../assets/images/MX/EDGY/H-JIPPER EDGY V2 JERSEY.png'),
                back : require('../assets/images/MX/EDGY/T-JIPPER EDGY V2 JERSEY.png'),
            },
            images: [
                require('../assets/images/MX/EDGY/H-JIPPER EDGY V2 JERSEY.png'),
                require('../assets/images/MX/EDGY/T-JIPPER EDGY V2 JERSEY.png'),
                require('../assets/images/MX/EDGY/2-H JIPPER EDGY OENAL.png'),
                require('../assets/images/MX/EDGY/H-JIPPER EDGY PANTS.png'),
                require('../assets/images/MX/EDGY/REV2-2-T JIPPER EDGY ONEAL.png'),
                require('../assets/images/MX/EDGY/T-JIPPER EDGY PANTS.png'),
            ]
        },
        {
            id: 8,
            name: "FARMER",
            slug: "jpmx-8",
            image: {
                front : require('../assets/images/MX/FARMER/jersey-farmer-front.png'),
                back : require('../assets/images/MX/FARMER/catalog-template.png'),
            },
            images: [
                require('../assets/images/MX/FARMER/jersey-farmer-front.png'),
                require('../assets/images/MX/FARMER/catalog-template.png'),
                require('../assets/images/MX/FARMER/pant-farmer-back.png'),
                require('../assets/images/MX/FARMER/pant-farmer-front.png'),
            ]
        },
        {
            id: 9,
            name: "FORMAL",
            slug: "jpmx-9",
            image: {
                front : require('../assets/images/MX/FORMAL/catalog-template.png'),
                back : require('../assets/images/MX/FORMAL/JERSEY-BACK.png'),
            },
            images: [
                require('../assets/images/MX/FORMAL/catalog-template.png'),
                require('../assets/images/MX/FORMAL/JERSEY-BACK.png'),
                require('../assets/images/MX/FORMAL/PANT-BACK.png'),
                require('../assets/images/MX/FORMAL/PANT-FONT.png'),
            ]
        },
        {
            id: 10,
            name: "GALAXY",
            slug: "jpmx-10",
            image: {
                front : require('../assets/images/MX/GALAXY/F-JERSEY MX JIPPER V7.png'),
                back : require('../assets/images/MX/GALAXY/B-JERSEY MX JIPPER V7.png'),
            },
            images: [
                require('../assets/images/MX/GALAXY/F-JERSEY MX JIPPER V7.png'),
                require('../assets/images/MX/GALAXY/B-JERSEY MX JIPPER V7.png'),
                require('../assets/images/MX/GALAXY/H-GALAXY MX JIPPER.png'),
                require('../assets/images/MX/GALAXY/H-JERSEY GALAXY JIPPER.png'),
                require('../assets/images/MX/GALAXY/T-GALAXY MX JIPPER.png'),
                require('../assets/images/MX/GALAXY/T-JERSEY GALAXY JIPPER.png'),
            ]
        },
        {
            id: 11,
            name: "GREEN",
            slug: "jpmx-11",
            image: {
                front : require('../assets/images/MX/GREEN/H-JIPPER MX GREEN JERSEY.png'),
                back : require('../assets/images/MX/GREEN/T-JIPPER MX GREEN JERSEY.png'),
            },
            images: [
                require('../assets/images/MX/GREEN/H-JIPPER MX GREEN JERSEY.png'),
                require('../assets/images/MX/GREEN/T-JIPPER MX GREEN JERSEY.png'),
                require('../assets/images/MX/GREEN/H-P-MX JIPPER GREEN.png'),
                require('../assets/images/MX/GREEN/T-P-MX JIPPER GREEN.png'),
            ]
        },
        {
            id: 12,
            name: "HXF",
            slug: "jpmx-12",
            image: {
                front : require('../assets/images/MX/HXF/catalog-template.png'),
                back : require('../assets/images/MX/HXF/JERSEY-BACK.png'),
            },
            images: [
                require('../assets/images/MX/HXF/catalog-template.png'),
                require('../assets/images/MX/HXF/JERSEY-BACK.png'),
                require('../assets/images/MX/HXF/PANT-BACK.png'),
                require('../assets/images/MX/HXF/PANT-FRONT.png'),
            ]
        },
        {
            id: 13,
            name: "JIPPER BLURZZ",
            slug: "jpmx-13",
            image: {
                front : require('../assets/images/MX/JIPPER BLURZZ/H-JERSEY JIPPER BLUZZ.png'),
                back : require('../assets/images/MX/JIPPER BLURZZ/T-JERSEY JIPPER BLUZZ.png'),
            },
            images: [
                require('../assets/images/MX/JIPPER BLURZZ/H-JERSEY JIPPER BLUZZ.png'),
                require('../assets/images/MX/JIPPER BLURZZ/T-JERSEY JIPPER BLUZZ.png'),
                require('../assets/images/MX/JIPPER BLURZZ/H-JIPPER BLURZZ PANTS.png'),
                require('../assets/images/MX/JIPPER BLURZZ/T-JIPPER BLURZZ PANTS.png'),
            ]
        },
        {
            id: 14,
            name: "JIPPER MAYHEM",
            slug: "jpmx-14",
            image: {
                front : require('../assets/images/MX/JIPPER MAYHEM/H-MAYHEM JIPPER.png'),
                back : require('../assets/images/MX/JIPPER MAYHEM/T-MAYHEM JIPPER.png'),
            },
            images: [
                require('../assets/images/MX/JIPPER MAYHEM/H-MAYHEM JIPPER.png'),
                require('../assets/images/MX/JIPPER MAYHEM/T-MAYHEM JIPPER.png'),
                require('../assets/images/MX/JIPPER MAYHEM/H-JIPPER MAYHEM PANTS.png'),
                require('../assets/images/MX/JIPPER MAYHEM/T-JIPPER MAYHEM PANTS.png'),
            ]
        },
        {
            id: 15,
            name: "PINK JIPPER",
            slug: "jpmx-15",
            image: {
                front : require('../assets/images/MX/PINK JIPPER/H-JERSEY MX PINK JIPPER.png'),
                back : require('../assets/images/MX/PINK JIPPER/T-JERSEY MX PINK JIPPER.png'),
            },
            images: [
                require('../assets/images/MX/PINK JIPPER/H-JERSEY MX PINK JIPPER.png'),
                require('../assets/images/MX/PINK JIPPER/T-JERSEY MX PINK JIPPER.png'),
                require('../assets/images/MX/PINK JIPPER/H-PINK JIPPER PANTS.png'),
                require('../assets/images/MX/PINK JIPPER/T-PINK JIPPER PANTS.png'),
            ]
        },
        {
            id: 16,
            name: "XV",
            slug: "jpmx-16",
            image: {
                front : require('../assets/images/MX/XV/xv-front.png'),
                back : require('../assets/images/MX/XV/xv-back.png'),
            },
            images: [
                require('../assets/images/MX/XV/xv-front.png'),
                require('../assets/images/MX/XV/xv-back.png'),
                require('../assets/images/MX/XV/xv-pback.png'),
                require('../assets/images/MX/XV/xv-pfront.png'),
            ]
        },
    ]
}