import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { postOrder } from '../config/api/orderCrud'

function Order() {

    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState('')
    const [team, setTeam] = useState('')
    const [state, setState] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [zipcode, setZipcode] = useState('')
    const [attachment, setAttachment] = useState()
    const [orderList, setOrderList] = useState()
    const [material, setMaterial] = useState('Dry Fit')
    const [size, setSize] = useState('classic')
    const [sizeChart, setSizeChart] = useState()
    const [rush, setRush] = useState(true)

    const handleSubmit = (e) => {
        e.preventDefault()
        const payload = new FormData()
        payload.append('team_name', team)
        payload.append('city', city)
        payload.append('email', email)
        payload.append('your_name', name)
        payload.append('state', state)
        payload.append('phone_number', phone)
        payload.append('shipping_address', address)
        payload.append('zip_code', zipcode)
        payload.append('order_list', orderList)
        payload.append('jersey_size_chart', sizeChart)
        payload.append('custom_jersey_size', size)
        payload.append('rush', rush)
        payload.append('jersey_material', material)
        
        for (let i = 0; i < attachment.length; i++) {
            payload.append('attachments[]', attachment[i])
        }

        dispatch(postOrder({payload})).then((res) => {
            if(res.payload.status === "success") {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Order Success',
                })
                window.location.href = '/'
            }
        }).catch((err) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong',
            })
        })
    }

    return (
        <div className="md:px-20 w-full mx-auto pb-4">
            <h1 className="text-2xl md:text-4xl font-bold text-center md:text-left text-white font-inter py-3 md:py-8">ORDER FORM</h1>
            <div className="md:w-5/6 w-full md:mb-8 my-3 md:my-0 md:mt-4">
                <form className="flex flex-col">
                    <label htmlFor="name" className="text-white font-inter">Team Name</label>
                    <input type="text" name="name" value={team} onChange={(e) => setTeam(e.target.value)} placeholder="Name" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="name" className="text-white font-inter">City</label>
                    <input type="text" name="name" value={city} onChange={(e) => setCity(e.target.value)} placeholder="City" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="email" className="text-white font-inter">Email</label>
                    <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="name" className="text-white font-inter">Your Name</label>
                    <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="name" className="text-white font-inter">State</label>
                    <input type="text" name="name" value={state} onChange={(e) => setState(e.target.value)} placeholder="State" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="phone" className="text-white font-inter">Phone Number</label>
                    <input type="text" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Phone Number" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="country" className="text-white font-inter">Shipping address</label>
                    <input type="text" name="country" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="Address" className="px-4 py-2 border-2 rounded-md my-2" required />
                    <label htmlFor="message" className="text-white font-inter">Zip Code</label>
                    <input type="text" name="country" value={zipcode} onChange={(e) => setZipcode(e.target.value)} placeholder="Zip Code" className="px-4 py-2 border-2 rounded-md my-2" required />
                </form>
            </div>
            <div className="w-full h-[2px] bg-oren"></div>
            <p className="pt-2 text-left text-white font-inter">{"Please attach the APPROVED designs here  (This is the design that will be printed, only attach the APPROVED design) *"}</p>
            <input onChange={(e) => setAttachment(e.target.files)} type="file" multiple name="image" accept='image/*' placeholder="browse images" className="px-4 py-2 bg-white border-2 max-w-80 rounded-md my-2" required />
            <p className="pt-2 text-left text-white font-inter">{"Order List (Including Name, Number, Size)  "}<span className="bg-oren py-1 px-2 rounded-md text-white text-sm underline cursor-pointer hover:text-blue-400 hidden md:inline">Excel template</span> </p>
            <p className="bg-oren px-2 w-[110px] rounded-md text-white text-sm underline cursor-pointer hover:text-blue-400 md:hidden">Excel template</p>
            <input onChange={(e) => setOrderList(e.target.files[0])} type="file" name="image" accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' placeholder="browse images" className="px-4 py-2 bg-white border-2 max-w-80 rounded-md my-2" required />
            <h4 className="text-white font-inter text-xl font-semibold pt-4">{"Jersey Material (All materials same price) "}<span className="text-gray-500 text-sm underline">Material Detail</span></h4>
            <div className="w-full flex flex-col gap-2 py-2">
                <div className="flex flex-row items-center hover:cursor-pointer" onClick={() => setMaterial('Dry Fit')}>
                    <div className={"h-2 w-2 rounded-full mr-1 " + (material === 'Dry Fit' ? 'bg-oren' : 'bg-white')}></div>
                    <p className="text-white font-inter">{"Dry Fit (Most popular, lighter smoother material, similar to dry fit)"}</p>
                </div>
                <div className="flex flex-row items-center hover:cursor-pointer" onClick={() => setMaterial('Mosas')}>
                    <div className={"h-2 w-2 rounded-full mr-1 " + (material === 'Mosas' ? 'bg-oren' : 'bg-white')}></div>
                    <p className="text-white font-inter">{"Mosas Dry Fit (Most popular, lighter smoother material, similar to dry fit)"}</p>
                </div>
                <div className="flex flex-row items-center hover:cursor-pointer" onClick={() => setMaterial('Hollow')}>
                    <div className={"h-2 w-2 rounded-full mr-1 " + (material === 'Hollow' ? 'bg-oren' : 'bg-white')}></div>
                    <p className="text-white font-inter">{"Hollow Dry Fit (Most popular, lighter smoother material, similar to dry fit)"}</p>
                </div>
            </div>
            <h4 className="text-white font-inter text-xl font-semibold pt-4">{"Jersey Size Charts: "}<span className="text-gray-500 text-sm underline">Size Chart</span></h4>
            <div className="w-full flex flex-col gap-2 py-2">
                <div className="flex flex-row items-center hover:cursor-pointer" onClick={() => setSize('classic')}>
                    <div className={"h-2 w-2 rounded-full mr-1 " + (size === 'classic' ? 'bg-oren' : 'bg-white')}></div>
                    <p className="text-white font-inter">{"Classic Fit Jersey (Default Sizing)"}</p>
                </div>
                <div className="flex flex-row items-center hover:cursor-pointer" onClick={() => setSize('custom')}>
                    <div className={"h-2 w-2 rounded-full mr-1 " + (size === 'custom' ? 'bg-oren' : 'bg-white')}></div>
                    <p className="text-white font-inter">{"Custom Size Chart (extra $3 per item)"}</p>
                </div>
                <input onChange={(e) => setSizeChart(e.target.files[0])} type="file" name="image" accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' placeholder="browse images" className="px-4 py-2 bg-white border-2 max-w-80 rounded-md my-2" required />
            </div>
            <h4 className="text-white font-inter text-xl font-semibold pt-4">{"RUSH SHIPPING Option : Adds $5 per item"}</h4>
            <div className="w-full flex flex-col gap-2 py-2">
                <div className="flex flex-row items-center hover:cursor-pointer" onClick={() => setRush(true)}>
                    <div className={"h-2 w-2 rounded-full mr-1 " + (rush === true ? 'bg-oren' : 'bg-white')}></div>
                    <p className="text-white font-inter">{"Yes, i need rush shipping for an extra $5 per item (5 - 7 days)"}</p>
                </div>
                <div className="flex flex-row items-center hover:cursor-pointer" onClick={() => setRush(false)}>
                    <div className={"h-2 w-2 rounded-full mr-1 " + (rush === false ? 'bg-oren' : 'bg-white')}></div>
                    <p className="text-white font-inter">{"No, standard shipping is fine (10 - 12 days)"}</p>
                </div>
            </div>
            <div className="w-full flex flex-col items-center justify-center">
                <button type="submit" onClick={handleSubmit} className="bg-oren text-white font-inter py-2 px-8 rounded-2xl my-2 ">Submit Order</button>
                <p className="pt-2 text-left text-white font-inter"><span className="text-oren">NOTE:</span>{" Once your order is submitted, we will reach out to you via email with a total and payment info for the order. Thank you for ordering from Jipper Design!"}</p>
            </div>
        </div>
    )
}

export default Order