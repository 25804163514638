import React from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { postDyo } from '../config/api/dyoCrud';

function ConfirmDesign() {

    const location = useLocation();
    
    const dispatch = useDispatch();

    const file = []
    const att = []

    if(location.state === null) {
        return window.location.href = '/design-your-own'
    } else {
        for (let i = 0; i < location.state.attachments.length; i++) {
            file.push(location.state.attachments[i].name)
        }

        for (let i = 0; i < location.state.attRef.length; i++) {
            att.push(location.state.attRef[i].name)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        
        const fd = new FormData()
        fd.append('team',location.state.team)
        fd.append('name',location.state.name)
        fd.append('phone',location.state.phone)
        fd.append('email',location.state.email)
        fd.append('id_ref',location.state.ref)
        fd.append('description',location.state.desc)

        for (let i = 0; i < location.state.attachments.length; i++) {
            fd.append('attachments[]',location.state.attachments[i])
        }

        for (let i = 0; i < location.state.attRef.length; i++) {
            fd.append('attachmentRefs[]',location.state.attRef[i])
        }

        dispatch(postDyo({ payload: fd })).then((res) => {
            if(res.payload.status === "success") {

                const c = {
                    id: res.payload.data.dyo.id,
                    name: "Design Request",
                    price: 35,
                    quantity: 1,
                    isProduct: false
                }

                const cart = JSON.parse(localStorage.getItem('cart') || "[]")

                cart.push(c)

                localStorage.setItem('cart', JSON.stringify(cart))
                window.location.href = '/cart'
            }
        })

    }

    return (
        <div className="max-w-7xl w-full mx-auto py-10">
            <div className="w-11/12 mx-auto">
                <div className="my-6">
                    <h1 className="text-2xl font-bold text-center text-white font-inter mb-4">DESIGN REQUEST</h1>
                    <form className="flex flex-col mt-10">
                        <label htmlFor="name" className="text-white font-inter">{"Team Name (on jersey)"}</label>
                        <h1 className="px-4 py-2 border-2 rounded-md my-2 bg-white">{location.state.team || "Team Name"}</h1>
                        <label htmlFor="country" className="text-white font-inter">Your Name</label>
                        <h1 className="px-4 py-2 border-2 rounded-md my-2 bg-white">{location.state.name || "Name"}</h1>
                        <label htmlFor="phone" className="text-white font-inter">Phone Number</label>
                        <h1 className="px-4 py-2 border-2 rounded-md my-2 bg-white">{location.state.phone || "Phone"}</h1>
                        <label htmlFor="email" className="text-white font-inter">Email</label>
                        <h1 className="px-4 py-2 border-2 rounded-md my-2 bg-white">{location.state.email || "Email"}</h1>
                        <div className="w-full h-[2px] bg-oren my-5"></div>
                        <label htmlFor="images" className="text-white font-inter">Attachments: Any Designs, logo, sponsors, high resolution images, etc.</label>
                        <div className="flex flex-row items-center gap-8">
                            {file.map((item, index) => (
                                <div key={index} className="px-4 py-2 bg-gray-400 border-2 rounded-md my-2">
                                    {item}
                                </div>
                            ))}
                        </div>
                        <label htmlFor="email" className="text-white font-inter">Attachments: Design references</label>
                        <div className="flex flex-row gap-8">
                            {att.map((item, index) => ( 
                                <div key={index} className="px-4 py-2 bg-gray-400 border-2 rounded-md my-2">
                                    {item}
                                </div>
                            ))}
                        </div>
                        <label htmlFor="email" className="text-white font-inter mt-3">{"Describe what you want on your design  (A clearer brief will help our team create a design according to your wishes)"}</label>
                        <textarea type="text" value={location.state.desc} name="phone" placeholder="..." className="px-4 py-2 border-2 border-gray-300 rounded-md my-2" required />
                        <button onClick={handleSubmit} className="bg-oren text-white font-inter py-2 px-4 rounded-md max-w-80 mx-auto">SUBMIT</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDesign