import { Dropdown } from 'flowbite-react'
import React, { useEffect, useState } from 'react'


function Info() {

    const [selectedMaterial, setSelectedMaterial] = useState('drifit')

    useEffect(() => {
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        console.log(href);
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <div className="md:px-20 w-full mx-auto py-3 md:py-10">
            <div className="w-full flex flex-row justify-between items-center mb-8">
                <h1 id='size-chart' className="text-2xl md:text-5xl font-bold text-left text-white font-inter">Size Chart</h1>
                <Dropdown color={'light'} className='rounded-xl focus:border-0 focus:ring-0 focus:outline-none active:outline-none active:ring-0 active:border-0 focus:ring-transparent' label="Category">
                    <Dropdown.Item onClick={() => alert('Dashboard!')}>Dashboard</Dropdown.Item>
                    <Dropdown.Item onClick={() => alert('Settings!')}>Settings</Dropdown.Item>
                    <Dropdown.Item onClick={() => alert('Earnings!')}>Earnings</Dropdown.Item>
                    <Dropdown.Item onClick={() => alert('Sign out!')}>Sign out</Dropdown.Item>
                </Dropdown>
            </div>
            <img src="https://images.unsplash.com/photo-1531170926158-eb77317f2197?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="Size Chart" className="w-full md:h-[480px]" style={{ objectFit: 'cover' }} />
            <h1 id='price-list' className="text-2xl md:text-5xl font-bold text-left text-white font-inter my-8">Price List</h1>
            <img src={require('../assets/pricelist.png')} alt="" className='md:w-1/2 h-auto' />
            <div className="">
                <h1 id='material-detail' className="text-2xl md:text-5xl font-bold text-left text-white font-inter my-8">Material Detail</h1>
                <div className="flex flex-row gap-2">
                    <div onClick={() => setSelectedMaterial('drifit')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'drifit' ? 'bg-oren' : '')}>DRIFIT</div>
                    <div onClick={() => setSelectedMaterial('hollow')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'hollow' ? 'bg-oren' : '')}>HOLLOW</div>
                    <div onClick={() => setSelectedMaterial('mosas')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'mosas' ? 'bg-oren' : '')}>MOSAS</div>
                    <div onClick={() => setSelectedMaterial('embos')} className={"px-2 py-1 text-white font-inter rounded-sm text-xl hover:cursor-pointer hover:scale-105 " + (selectedMaterial === 'embos' ? 'bg-oren' : '')}>EMBOS</div>
                </div>
            </div>
        </div>
    )
}

export default Info