import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { isValidEmail } from '../config/globalFunction'
import { useDispatch, useSelector } from 'react-redux'
import { postCheckout } from '../config/api/checkoutCrud'

function Checkout() {

  const navigate = useNavigate()

  const cart = JSON.parse(localStorage.getItem('cart') || "[]")

  const dispatch = useDispatch()

  const {isLoading} = useSelector(state => state.checkout)

  const [mail, setMail] = useState('')
  const [country, setCountry] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [address, setAddress] = useState('')
  const [appartment, setAppartment] = useState('')
  const [city, setCity] = useState('')
  const [province, setProvince] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [phone, setPhone] = useState('')
  const [shipping, setShipping] = useState('')  

  if (cart.length === 0) {
    navigate('/')
  }

  const dyo = useMemo(() => {
    return cart.filter(item => item.isProduct === false)
  }, [cart])

  const product = useMemo(() => {
    return cart.filter(item => item.isProduct === true)
  }, [cart])
  
  const subtotal = useMemo(() => {
    return cart.reduce((acc, item) => {
      return acc + item.price * item.quantity
    }, 0)
  }, [cart])

  const tax = subtotal * 0.05

  const handleSubmit = (e) => {
    e.preventDefault()

    if(mail === '' || country === '' || firstName === '' || address === '' || city === '' || province === '' || postalCode === '' || phone === '' || shipping === '') {
      return Swal.fire({
        title: 'Attention',
        text: 'Please fill in all the fields',
        icon: 'warning',
        confirmButtonText: 'Yes'
      })
    }

    if(isValidEmail.test(mail) === false) {
      return Swal.fire({
        title: 'Attention',
        text: 'Please enter a valid email',
        icon: 'warning',
        confirmButtonText: 'Yes'
      })
    }

    let fd = {
      email: mail,
      country,
      name : `${firstName} ${lastName}`,
      address,
      address_detail: appartment,
      city,
      province,
      postal_code: postalCode,
      phone,
      shipping_method: shipping,
      shipping_fee: 0,
      subtotal,
      tax,
      total: subtotal + tax
    }

    if(dyo.length > 0) {
      fd = {
        ...fd,
        'is_product': 0,
        'id_dyo': dyo[0].id
      }
    }

    if(product.length > 0) {
      fd = {
        ...fd,
        'is_product': 1,
        'product_orders': product
      }
    }

    dispatch(postCheckout({ payload: fd })).then((res) => {
      if( res && res.payload && res.payload.status === "success") {
        localStorage.removeItem('cart')
        navigate('/confirm/' + res.payload.data.id)
      }
    })

  }

  return (
    <div className="max-w-7xl w-full mx-auto md:py-10 py-4 min-h-screen">
      <div className="w-11/12 mx-auto flex flex-col">
        <h1 className="text-4xl font-bold text-left text-white font-inter uppercase">Checkout your order</h1>
        <div className="w-full flex flex-col-reverse md:flex-row mt-3 md:mt-8 gap-3 md:gap-8">
          {/* kanan */}
          <div className="md:w-1/2">
            <h2 className='text-2xl font-bold text-left text-white font-inter uppercase'>Contact</h2>
            <input type="email" name="name" value={mail} onChange={(e) => setMail(e.target.value)} placeholder="mail@mail.com" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />

            <h2 className='text-2xl font-bold text-left text-white font-inter uppercase'>Delivery</h2>
            <input type="text" name="name" value={country} onChange={(e) => setCountry(e.target.value)} placeholder="country" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
            <div className="flex flex-row gap-3">
              <input type="text" name="name" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
              <input type="text" name="name" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
            </div>
            <input type="text" name="name" value={address} onChange={(e) => setAddress(e.target.value)} placeholder="address" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
            <input type="text" name="name" value={appartment} onChange={(e) => setAppartment(e.target.value)} placeholder="appartment, suite, etc" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
            <input type="text" name="name" value={city} onChange={(e) => setCity(e.target.value)} placeholder="city" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
            <div className="flex flex-row gap-3">
              <input type="text" name="name" value={province} onChange={(e) => setProvince(e.target.value)} placeholder="province" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
              <input type="text" name="name" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} placeholder="postal code" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
            </div>
            <input type="text" name="name" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="phone" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />

            <h2 className='text-2xl font-bold text-left text-white font-inter uppercase'>Shipping method</h2>
            <input type="text" name="name" value={shipping} onChange={(e) => setShipping(e.target.value)} placeholder="international shipping" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />

            <button disabled={isLoading} onClick={handleSubmit} type="submit" className="bg-oren w-full text-white font-inter text-xl font-medium py-3 px-6 rounded-md md:max-w-80 mx-auto mt-5 uppercase">{isLoading ? 'Loading...' : 'Pay Now'}</button>
          </div>
          {/* kiri */}
          <div className="md:w-1/2 h-96 md:h-[650px] bg-white p-5">
            <div className="flex flex-col items-center h-[80%] overflow-y-auto">
              {/* body */}
              {cart.map((item, index) => (
                <div key={index} className="flex flex-row justify-between items-center w-full mb-5">
                  <div className="flex flex-row gap-5">
                    <div className="w-36 h-20">
                      <img src={item.image} alt="checkout" className="" />
                    </div>
                    <div className="">
                      <h1 className="text-2xl font-bold text-left text-black font-inter">{item.name}</h1>
                      {item.isPoduct ? 
                        <>
                          <h1 className="text-xl font-medium text-left text-black font-inter">Color: Black</h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">Size: 42</h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">Gender: Man</h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">Material : Drifit</h1>
                        </>
                        :
                        <>
                          <h1 className="text-xl font-medium text-left text-black font-inter">$ {item.price}</h1>
                          <h1 className="text-xl font-medium text-left text-black font-inter">quantity: {item.quantity}</h1>
                        </>
                      }
                    </div>
                  </div>
                  <div className={"flex flex-col " + (item.isPoduct ? "" : "hidden")}>
                    <h1 className="text-xl font-medium text-left text-black font-inter">$0.00</h1>
                    <h1 className="text-xl font-medium text-right text-black font-inter">x 1</h1>
                  </div>
                </div>
              ))}
            </div>
            <div className="w-full h-[20%] flex flex-col">
              <div className="flex flex-row justify-between items-center border-b-2">
                <h1 className="text-xl font-medium text-left text-black font-inter">Subtotal</h1>
                <h1 className="text-xl font-medium text-left text-black font-inter">$ {subtotal}</h1>
              </div>
              <div className="flex flex-row justify-between items-center border-b-2">
                <h1 className="text-xl font-medium text-left text-black font-inter">Shipping</h1>
                <h1 className="text-xl font-medium text-left text-black font-inter">$0.00</h1>
              </div>
              <div className="flex flex-row justify-between items-center border-b-2">
                <h1 className="text-xl font-medium text-left text-black font-inter">Tax</h1>
                <h1 className="text-xl font-medium text-left text-black font-inter">$ {tax}</h1>
              </div>
              <div className="flex flex-row justify-between items-center">
                <h1 className="text-3xl font-bold text-left text-black font-inter">Total</h1>
                <h1 className="text-3xl font-bold text-left text-black font-inter">$ {subtotal+tax}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checkout