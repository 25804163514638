import React from 'react'
import Logo from "../assets/logo.png"

function Footer() {
    return (
        <div className="flex mx-auto w-full md:h-[320px]">
            <div className="flex w-full flex-row justify-between md:px-16 md:pt-4">
                <div className="w-2/6">
                    <div className="">
                        <img src={Logo} alt="logo" className='h-12 md:h-32 w-12 md:w-32' />
                    </div>
                    <div className="max-w-60 py-2 flex flex-row gap-1 md:gap-3">
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <svg className="w-5 md:w-9 h-5 md:h-9 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                <path fill="currentColor" fillRule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <svg className="w-5 md:w-9 h-5 md:h-9 text-white dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                <path fillRule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <svg className="w-5 md:w-9 h-5 md:h-9 text-white dark:text-white" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="5" width="18" height="14" rx="2" ry="2"></rect><polyline points="3 7 12 13 21 7"></polyline>
                            </svg>
                        </div>
                        <div className="border border-white rounded-full w-6 md:w-10 h-6 md:h-10 flex justify-center items-center font-bold">
                            <svg className="w-5 md:w-9 h-5 md:h-9 text-white dark:text-white" aria-hidden="true" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="5" width="18" height="14" rx="2" ry="2"></rect><polyline points="3 7 12 13 21 7"></polyline>
                            </svg>                            
                        </div>
                    </div>
                    <div className="max-w-60">
                        <h3 className='text-white text-xl md:text-3xl font-inter font-bold'>JIPPER DESIGN 2020</h3>
                    </div>
                </div>
                <div className="w-3/6 pt-5 pb-4 md:pb-0">
                    <div className="flex flex-row gap-2 md:gap-10">
                        <div className="">
                            <h2 className='text-oren text-xl md:text-2xl font-inter font-bold'>PARTNER WITH US</h2>
                            <p className='text-white/60 pt-2 md:pt-4 font-inter'>Sponsorship</p>
                            <p className='text-white/60 pt-1 font-inter'>Whole sale</p>
                        </div>
                        <div className="">
                            <h2 className='text-oren text-xl md:text-2xl font-inter font-bold'>CUSTOMER CARE</h2>
                            <p className='text-white/60 pt-2 md:pt-4 font-inter'>FAQ</p>
                            <p className='text-white/60 pt-1 font-inter'>Term & Condition</p>
                            <p className='text-white/60 pt-1 font-inter'>Contact Us</p>
                            <p className='text-white/60 pt-1 font-inter'>Articles</p>
                        </div>
                    </div>
                </div>
                <div className="w-1/6"></div>
            </div>
        </div>
    )
}

export default Footer