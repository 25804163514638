import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getCheckoutById } from '../config/api/checkoutCrud'
import Swal from 'sweetalert2'
import { postConfirmPayment } from '../config/api/confirmPayment'

function PaymentConfirm() {

    const {id} = useParams()

    const dispatch = useDispatch()
    const {checkout} = useSelector((state) => state.checkout)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [file, setFile] = useState()
    const [email, setEmail] = useState('')

    const handleSubmit = (e) => {
        e.preventDefault()

        if(firstName === '' || lastName === '' || file === undefined || email === '') {
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill in all the fields',
            })
        }

        const payload = new FormData()
        payload.append('name', `${firstName} ${lastName}`)
        payload.append('bukti', file)
        payload.append('email', email)
        payload.append('id_order', id)

        dispatch(postConfirmPayment({payload})).then((res) => {
            if(res && res.payload && res.payload.status === "success") {
                setFile()
                setFirstName('')
                setLastName('')
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Payment Confirmation Success',
                })
                window.location.href = '/'
            }
        }).catch((err) => {
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Payment Confirmation Failed',
            })
        })

    }

    useEffect(() => {
        dispatch(getCheckoutById({ id })).then((res) => {
            if(res && res.payload && res.payload.status === "success") {
                
            } else {
                window.location.href = '/'
            }
        })
    }, [dispatch, id])

    return (
        <div className="max-w-7xl w-full mx-auto py-4 md:py-10 min-h-screen">
            <div className="w-11/12 mx-auto flex flex-col">
                <h1 className="text-4xl font-bold text-left text-white font-inter uppercase">Payment Confirmation</h1>
                <p className="text-xl text-left text-white font-inter mt-3">Bill the Payment to my Paypal account {" "}
                    <span className="font-bold">
                        (PayPal : iamirfanh@gmail.com)
                    </span>
                </p>
                <div className="w-full flex flex-col-reverse md:flex-row mt-8 gap-3 md:gap-8">
                    {/* kanan */}
                    <div className="md:w-1/2">
                        <h2 className='text-2xl font-bold text-left text-white font-inter uppercase'>Paypal Account</h2>
                        <div className="flex flex-row gap-3">
                            <input type="text" name="name" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First Name" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
                            <input type="text" name="name" value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder="Last Name" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />
                        </div>
                        <input type="text" name="name" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="mail@mail.com" className="px-4 py-2 border-2 rounded-md my-2 w-full" required />

                        <div className="">
                        <h2 className='text-2xl font-bold text-left text-white font-inter uppercase'>Proof Bill The Payment</h2>
                        <input onChange={(e) => setFile(e.target.files[0])} type="file" name="image" accept='image/*' placeholder="browse images" className="px-4 py-2 bg-white border-2 max-w-80 rounded-md my-2" required />
                        </div>

                        <div className="flex items-center justify-center">
                        <button onClick={handleSubmit} type="submit" className="bg-oren text-white font-inter text-xl font-medium py-3 px-6 rounded-md max-w-80 mx-auto mt-5 uppercase">Pay Now</button>
                        </div>
                    </div>
                    {/* kiri */}
                    <div className="md:w-1/2 h-[420px] md:h-[650px] bg-white p-5">
                        <div className="flex flex-col items-center h-[80%] overflow-y-auto">
                            {/* body */}
                            {checkout?.product_orders?.map((item) => (
                                <div key={item.id} className="flex flex-row justify-between items-center w-full mb-5">
                                    <div className="flex flex-row gap-5">
                                        <div className="w-36 h-20">
                                            <img src={require('../assets/front.png')} alt="checkout" className="" />
                                        </div>
                                        <div className="">
                                            <h1 className="text-2xl font-bold text-left text-black font-inter">ID: {item.id_product}</h1>
                                            <h1 className="text-xl font-medium text-left text-black font-inter">Size: {item.size}</h1>
                                            <h1 className="text-xl font-medium text-left text-black font-inter">Gender: {item.gender}</h1>
                                            <h1 className="text-xl font-medium text-left text-black font-inter">Material : {item.material}</h1>
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        <h1 className="text-xl font-medium text-left text-black font-inter">$ {item.price}</h1>
                                        <h1 className="text-xl font-medium text-right text-black font-inter">x {item.quantity}</h1>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full h-[20%] flex flex-col">
                            <div className="flex flex-row justify-between items-center border-b-2">
                                <h1 className="text-xl font-medium text-left text-black font-inter">Subtotal</h1>
                                <h1 className="text-xl font-medium text-left text-black font-inter">$ {checkout.subtotal}</h1>
                            </div>
                            <div className="flex flex-row justify-between items-center border-b-2">
                                <h1 className="text-xl font-medium text-left text-black font-inter">Shipping</h1>
                                <h1 className="text-xl font-medium text-left text-black font-inter">$ {checkout.shipping_fee}</h1>
                            </div>
                            <div className="flex flex-row justify-between items-center border-b-2">
                                <h1 className="text-xl font-medium text-left text-black font-inter">Tax</h1>
                                <h1 className="text-xl font-medium text-left text-black font-inter">$ {checkout.tax}</h1>
                            </div>
                            <div className="flex flex-row justify-between items-center">
                                <h1 className="text-3xl font-bold text-left text-black font-inter">Total</h1>
                                <h1 className="text-3xl font-bold text-left text-black font-inter">$ {checkout.total}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentConfirm