import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { API_DYO } from "..";

export const getDyo = createAsyncThunk("dyo/getDyo", async () => {
  try {
    const response = await axios.get(API_DYO);
    return response.data;
  } catch (error) {
    console.log(error);
    Swal.fire({
      title: "Attention",
      text: error.message,
      icon: "warning",
      confirmButtonText: "Yes",
    });
    return Promise.reject(error);
  }
});

export const postDyo = createAsyncThunk("dyo/postDyo", async ({ payload }) => {
  try {
    const response = await axios.post(API_DYO+'-posts', payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    Swal.fire({
      title: "Attention",
      text: error.message,
      icon: "warning",
      confirmButtonText: "Yes",
    });
    return Promise.reject(error);
  }
});
