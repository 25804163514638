import React from 'react'

function Contact() {
    return (
        <div className="md:px-20 w-full mx-auto py-3 px-4 md:py-10">
            <div className="flex flex-col md:flex-row mx-auto md:w-5/6 py-3 md:py-10 md:gap-x-10">
                <div className="md:w-1/3 flex flex-col gap-6">
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center gap-4" >
                            <div className="w-16 h-16 bg-oren rounded-full flex justify-center items-center">
                                <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M22 6l-10 7L2 6" />
                                </svg>
                            </div>
                            <h2 className="text-3xl font-medium font-inter text-white">Whatsapp</h2>
                        </div>
                        <p className="text-xl font-inter text-white pt-4">We are available 24/7.</p>
                        <p className="text-xl font-inter font-bold text-white pt-4">Phone: +62 851 6128 0148</p>
                    </div>
                    <div className="w-full h-[2px] bg-oren"></div>
                    <div className="flex flex-col">
                        <div className="flex flex-row items-center gap-4" >
                            <div className="w-16 h-16 bg-oren rounded-full flex justify-center items-center">
                                <svg className="w-10 h-10 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M22 6l-10 7L2 6" />
                                </svg>
                            </div>
                            <h2 className="text-3xl font-medium font-inter text-white">Write To US</h2>
                        </div>
                        <p className="text-xl font-inter text-white pt-4">Fill out our form and we will contact you within 24 hours.</p>
                        <p className="text-xl font-inter font-bold text-white pt-4">Email : customer@jipperdesign.com</p>
                        <p className="text-xl font-inter font-bold text-white pt-4">Email : support@jipperdesign.com</p>
                    </div>
                </div>
                <div className="md:w-2/3 flex flex-col gap-6 pt-6 md:pt-0">
                    <div className="flex flex-row gap-4">
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-lg" placeholder="Name" />
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-lg" placeholder="Email" />
                        <input type="text" className="w-full p-2 border border-gray-300 rounded-lg" placeholder="Subject" />
                    </div>
                    <textarea className="w-full h-40 p-2 border border-gray-300 rounded-lg" placeholder="Message"></textarea>
                    <button className="w-full mt-4 p-2 bg-oren text-white rounded-lg">Send</button>
                </div>
            </div>
        </div>
    )
}

export default Contact