import { createSlice } from "@reduxjs/toolkit";
import { getCheckoutById, postCheckout } from "../api/checkoutCrud";

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    checkout: {},
    isLoading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCheckout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCheckout.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postCheckout.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCheckoutById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCheckoutById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checkout = action.payload.data;
      })
      .addCase(getCheckoutById.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default checkoutSlice.reducer;