import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import Bintang from '../components/Bintang';
import Swal from 'sweetalert2';
import { categories, products } from '../config/products';

function Detail() {

    const { id } = useParams();

    const [name, setName] = useState('')
    const [number, setNumber] = useState('')
    const [size, setSize] = useState('')
    const [gender, setGender] = useState('')
    const [material, setMaterial] = useState('')
    const [quantity, setQuantity] = useState(1)
    const [selectedPicture, setSelectedPicture] = useState(0)

    const cart = JSON.parse(localStorage.getItem('cart') || "[]")

    const cat = id.split('-')[0]

    const category = useMemo(() => {
        return categories.find(item => item.alias === cat)
    }, [cat])

    const prod = useMemo(() => {
        return products[category.slug].find(item => item.slug === id)
    }, [id])

    const addToCart = () => {
        if (name === '' || number === '' || size === '' || gender === '' || material === '') {
            return Swal.fire({
                title: 'Attention',
                text: 'Please fill in all the fields',
                icon: 'warning',
                confirmButtonText: 'Yes'
            })
        }

        const c = {
            id: id,
            size: size,
            name_back: name,
            number_back: number,
            gender: gender,
            material: material,
            name: prod.name,
            price: 20,
            quantity: quantity,
            isProduct: true,
            image : prod.image?.front
        }

        cart.push(c)

        localStorage.setItem('cart', JSON.stringify(cart))
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        setGender('')
        setMaterial('')
        setNumber('')
        setName('')
        setSize('')
        if(width > 768) {
            window.location.href = '/cart'
        } else {
            Swal.fire({
                title: 'Success',
                text: 'Product added to cart',
                icon: 'success',
                confirmButtonText: 'Yes'
            })
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="max-w-7xl w-full mx-auto py-10 px-4">
            <div className="w-full flex flex-col md:flex-row gap-4">
                <div className="w-full md:w-7/12 ">
                    <div className="w-full flex flex-col-reverse md:flex-row gap-3">
                        <div className="w-full md:w-3/12 flex flex-row md:flex-col md:h-[540px] overflow-auto px-1 gap-2 ">
                            {prod?.images.map((item, index) => ( 
                                <img key={index} onClick={() => setSelectedPicture(index)} src={item} alt="" className='w-[46%] md:w-full bg-cover cursor-pointer hover:scale-105' />
                            ))}
                        </div>
                        <div className="w-full md:w-9/12 md:h-[540px]">
                            <img src={prod.images[selectedPicture]} alt="" className='w-full bg-cover' />
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-5/12 h-full">
                    <p className="text-gray-500 font-inter text-xs">ID : {id}</p>
                    <h2 className="text-2xl font-inter font-bold text-white">{prod.name}</h2>
                    <div className="flex items-center space-x-1 pt-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-oren" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-oren" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-oren" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <p className='text-gray-400 font-inter text-xs'>0 reviews</p>
                        <p className='text-green-400 font-inter text-xs'>In Stock</p>
                    </div>
                    <div className='flex items-center pt-2'>
                        <p className='text-white font-inter text-xl'>$ 20</p>
                    </div>
                    <form className='flex flex-col gap-2'>
                        <label className='text-white font-inter text-sm' htmlFor="name">Name on back of jersey</label>
                        <input className='bg-hitam border border-gray-600 rounded-lg px-2 py-1 text-white' type="text" id="name" name="name" required value={name} onChange={(e) => setName(e.target.value)} />
                        <label className='text-white font-inter text-sm' htmlFor="name">Number on jersey</label>
                        <input className='bg-hitam border border-gray-600 rounded-lg px-2 py-1 text-white' type="text" id="name" name="name" required value={number} onChange={(e) => setNumber(e.target.value)} />
                        <label className='text-white font-inter text-sm' htmlFor="size">Size</label>
                        <select className='bg-hitam border border-gray-600 rounded-lg px-2 py-1 text-white' name="size" id="size" required value={size} onChange={(e) => setSize(e.target.value)}>
                            <option value="">Select Size</option>
                            <option value="S">S</option>
                            <option value="M">M</option>
                            <option value="L">L</option>
                            <option value="XL">XL</option>
                        </select>
                        <label className='text-white font-inter text-sm' htmlFor="color">Gender</label>
                        <select className='bg-hitam border border-gray-600 rounded-lg px-2 py-1 text-white' name="color" id="color" required value={gender} onChange={(e) => setGender(e.target.value)}>
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                        <label className='text-white font-inter text-sm' htmlFor="color">Material</label>
                        <select className='bg-hitam border border-gray-600 rounded-lg px-2 py-1 text-white' name="color" id="color" required value={material} onChange={(e) => setMaterial(e.target.value)}>
                            <option value="">Select Material</option>
                            <option value="df">Dry fit</option>
                            <option value="mdf">Mosas Dry fit</option>
                        </select>
                    </form>
                    <div className="flex flex-row items-center py-2">
                        <p className='text-white font-inter text-sm'>Quantity : </p>
                        <div className="flex flex-row ml-2">
                            <button disabled={quantity === 1} onClick={() => setQuantity(quantity - 1)} className='bg-hitam border border-gray-600 px-2 py-1 text-white'>-</button>
                            <button className='bg-hitam border border-gray-600 px-4 py-1 text-white'>{quantity}</button>
                            <button onClick={() => setQuantity(quantity + 1)} className='bg-oren border border-oren px-2 py-1 text-white'>+</button>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center gap-5 py-8">
                        <button onClick={addToCart} className='bg-white border w-full border-oren px-4 py-2 rounded-lg font-inter'>Add to Cart</button>
                        <button className='bg-oren border w-full border-oren px-4 py-2 rounded-lg text-white font-inter'>Buy Now</button>
                    </div>
                    <p className='text-white font-inter font-semibold'>Click Design Request to change the team name, add a sponsor, change a color, and more.</p>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                        <div className="h-2 w-2 rounded-full bg-white mr-1"></div>
                        <p className="text-white font-inter">{"Custom name and number included in price."}</p>
                    </div>
                    <div className="flex flex-row items-center hover:cursor-pointer">
                        <div className="h-2 w-2 rounded-full bg-white mr-1"></div>
                        <p className="text-white font-inter">{"Available sizes from XS-6XL ."}</p>
                    </div>
                </div>
            </div>
            <div className="w-full md:py-8 py-4 md:px-16">
                <p className="text-white font-inter text-xl text-center">Customer Reviews</p>
                <div className="flex flex-col md:flex-row justify-between items-center gap-1 md:gap-4 py-2 md:py-8">
                    <div className="py-4 md:py-0">
                        <div className="flex items-center space-x-2 pt-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-oren" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-oren" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-oren" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-oren" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-white" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                            </svg>
                            <p className='text-gray-400 font-inter text-xs'>4.3 of 5</p>
                        </div>
                        <p className="text-white font-inter text-sm text-center">Based on 3 reviews</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row">
                            <div className="flex flex-row items-center justify-center gap-2">
                                <div className="flex space-x-0">
                                    {[...Array(5)].map((_, i) => (
                                        <Bintang key={i} active={i < 5} />
                                    ))}
                                </div>
                                <div className="w-24 h-5 bg-white ">
                                    <div className="w-1/5 h-full bg-oren"></div>
                                </div>
                                <p className='text-white font-inter text-sm'>0</p>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-row items-center justify-center gap-2">
                                <div className="flex space-x-0">
                                    {[...Array(5)].map((_, i) => (
                                        <Bintang key={i} active={i < 4} />
                                    ))}
                                </div>
                                <div className="w-24 h-5 bg-white ">
                                    <div className="w-4/5 h-full bg-oren"></div>
                                </div>
                                <p className='text-white font-inter text-sm'>0</p>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-row items-center justify-center gap-2">
                                <div className="flex space-x-0">
                                    {[...Array(5)].map((_, i) => (
                                        <Bintang key={i} active={i < 3} />
                                    ))}
                                </div>
                                <div className="w-24 h-5 bg-white ">
                                    <div className="w-1/5 h-full bg-oren"></div>
                                </div>
                                <p className='text-white font-inter text-sm'>0</p>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-row items-center justify-center gap-2">
                                <div className="flex space-x-0">
                                    {[...Array(5)].map((_, i) => (
                                        <Bintang key={i} active={i < 2} />
                                    ))}
                                </div>
                                <div className="w-24 h-5 bg-white ">
                                    <div className="w-1/5 h-full bg-oren"></div>
                                </div>
                                <p className='text-white font-inter text-sm'>0</p>
                            </div>
                        </div>
                        <div className="flex flex-row">
                            <div className="flex flex-row items-center justify-center gap-2">
                                <div className="flex space-x-0">
                                    {[...Array(5)].map((_, i) => (
                                        <Bintang key={i} active={i === 0} />
                                    ))}
                                </div>
                                <div className="w-24 h-5 bg-white ">
                                    <div className="w-1/5 h-full bg-oren"></div>
                                </div>
                                <p className='text-white font-inter text-sm'>0</p>
                            </div>
                        </div>
                    </div>
                    <button className="bg-oren text-white font-inter py-2 px-8 rounded-lg my-2 hidden md:block">Add a review</button>
                </div>
                <div className="w-full flex justify-center pt-4 md:hidden">
                    <button className="bg-oren text-white font-inter py-2 px-8 text-center rounded-lg my-2 md:hidden">Add a review</button>
                </div>
            </div>
        </div>
    )
}

export default Detail