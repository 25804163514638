import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Product from "./pages/Product";
import Wholesale from "./pages/Wholesale";
import Order from "./pages/Order";
import Detail from "./pages/Detail";
import DesignOwn from "./pages/DesignOwn";
import Contact from "./pages/Contact";
import Info from "./pages/Info";
import ConfirmDesign from "./pages/ConfirmDesign";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import PaymentConfirm from "./pages/PaymentConfirm";

function App() {

  function BasicLayout() {
    return (
      <div className="bg-black">
        <Header />
        <Outlet />
        <Footer />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={BasicLayout()} >
          <Route index element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/wholesale" element={<Wholesale />} />
          <Route path="/order" element={<Order />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/info" element={<Info />} />
          <Route path="/design-your-own" element={<DesignOwn />} />
          <Route path="/design-your-own/confirm" element={<ConfirmDesign />} />
          <Route path="/product/:id" element={<Detail />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirm/:id" element={<PaymentConfirm />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
